import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import "../styles/main.scss"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout"
import PageHeader from "../components/PageHeader"
import WhyTint from "../components/WhyTint"
import PageCarousel from "../components/PageCarousel"

export default function Home() {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "images/house-3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const sliderImages = [
    { alt: "Window insulation film", fileName: "residential.jpg" },
    { alt: "Window insulation film", fileName: "residential-2.jpg" },
    { alt: "Residential window tinting", fileName: "house-3.jpg" },
  ]

  return (
    <>
      <Helmet>
        <title>Residential Property Tinting | Window Magic</title>
        <meta
          name="description"
          content="Window tinting your residential property is a great way to provide safety and security to your family, pets and guests."
        />
      </Helmet>

      <Layout hidePageHeader={true}>
        <PageHeader
          fluid={data.file.childImageSharp.fluid}
          alt="Ranch slider tints"
          title="Residential"
        />

        <section className="page-section">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-lg-6">
                <p>
                  Window tinting your residential property is a great way to
                  provide safety and security to your family, pets and guests.
                  Window tinting reduces problem hot spots, glare, heat and
                  fluctuating temperature levels giving a more comfortable
                  environment. Many other benefits include:
                </p>
                <ul>
                  <li>
                    <strong>Burglary Prevention –</strong>&nbsp;the tint holds
                    the glass together and makes breaking in much more
                    difficult.
                  </li>
                  <li>
                    <strong>Safety – </strong>safety&nbsp;tinted windows don’t
                    shatter into lots of little pieces.
                  </li>
                  <li>
                    <strong>Privacy – </strong>window tinting can provide
                    one-way privacy where others can’t see inside your residence
                    but you can see out – privacy with no sacrifice on views!
                  </li>
                  <li>
                    <strong>Asset Protection –&nbsp;</strong>home interior can
                    get harsh sunlight damage such as faded furniture, carpet
                    and curtains. Window tinting can reject 99% of damaging UV
                    light, making your investments last longer.
                  </li>
                  <li>
                    <strong>Enhanced Appearance –&nbsp;</strong>tinted windows
                    are a great way to give your house a sleek polished look. If
                    you want safety and protection, window tinting is an
                    important addition to your residence.
                  </li>
                </ul>
                <p>
                  If you want safety and protection, window tinting is an
                  important addition to your residence.{" "}
                  <a href="tel:+64274449914">Call us now</a> to discuss your
                  requirements.
                </p>
              </div>
              <div className="col-lg-5 d-flex flex-column justify-content-center">
                <PageCarousel imagesToDisplay={sliderImages} />
              </div>
            </div>
          </div>
        </section>

        <WhyTint />
      </Layout>
    </>
  )
}
